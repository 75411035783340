<template>
  <div class="homePage2">
    <div class="title">
      <div class="text1">多好运产品生态</div>
      <div class="text2">标准、安全、合规</div>
    </div>
    <img class="pic" :src="img" alt="">
  </div>
</template>
<script>
export default {
  name: 'HomePage2',
  setup() {
    let img = require('@/assets/image/home/page2img@2x.png');

    return {
      img
    }
  }
}
</script>
<style lang="sass" scoped>
@import '@/style/colorAndSize.sass'
.homePage2
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  .title
    height: 25%
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: center
    .text1
      font-size: 0.54rem
      color: $fontColor
      font-weight: bold
      line-height: 0.75rem
      letter-spacing: 0.03rem
    .text2
      margin-top: 0.1rem
      font-size: 0.2rem
      color: $fontColor
      line-height: 0.28rem
      letter-spacing: 1px
  .pic
    margin-bottom: 0.3rem
    width: 10.21rem
    height: auto
</style>