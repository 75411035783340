// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/image/home/bg1.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/image/home/bg2.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/image/home/bg3.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/image/home/bg4.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter-active,.fade-leave-active{transition:opacity .2s}.fade-enter,.fade-leave-to{opacity:0}.homePage{width:100%;height:100%;overflow:hidden;position:relative}.homePage .fp-watermark{display:none!important}.homePage .fp-overflow{height:100%}.homePage .toTopBtn{width:.56rem;height:.56rem;position:fixed;right:.3rem;bottom:1.6rem;z-index:99;cursor:pointer}.homePage .section{background-size:cover;background-position:50%}.homePage .section1{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.homePage .section2{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.homePage .section3{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.homePage .section4{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.homePage .section5{background:#f6f6f6}.homePage .section6{background:#303335}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
