<template>
  <div class="homePage1">
    <div class="text1">大宗商品物流供应链</div>
    <div class="text1">解决方案</div>
    <div class="text2">创新与技术服务解决方案提供商，拥有行业领先解决方案，助您梦的启航</div>
    <div class="text3">2800+&nbsp;&nbsp;客户的选择</div>
  </div>
</template>
<script>
export default {
  name: 'HomePage1'
}
</script>
<style lang="sass" scoped>
@import '@/style/colorAndSize.sass'
.homePage1
  width: 100%
  height: 100%
  padding-top: $headerHeight
  display: flex
  flex-direction: column
  justify-content: center
  .text1
    letter-spacing: 0.04rem
    line-height: 0.75rem
    font-size: 0.66rem
    color: #ffffff
    text-shadow: 0px 2px 0.1rem rgba(30,55,58,0.7)
  .text2
    margin-top: 0.68rem
    font-size: 0.24rem
    color: #FFFFFF
    line-height: 0.33rem
    letter-spacing: 1px
    text-shadow: 0px 2px 0.06rem rgba(0,0,0,0.39)
  .text3
    margin-top: 0.27rem
    font-size: 0.19rem
    color: rgba(255,255,255,0.6)
    line-height: 0.26rem
    letter-spacing: 1px
    text-shadow: 0px 2px 0.06rem rgba(0,0,0,0.39)
</style>