import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/style/index.sass' // 主样式

// rem配置
import remConfig from './utils/remConfig'
remConfig()

// 全屏滚动 vue-fullpage.js
import 'vue-fullpage.js/dist/style.css'
import VueFullpage from 'vue-fullpage.js'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueFullpage)
app.mount('#app')
