import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/home/index.vue'
import { Home, ProductIntroduction, ApplicationScene, AboutUs, LogisticsPlatform, StoragePlatform, TradePlatform, LogisticsScenarios, StorageScenarios, SupplyChainScenarios } from '../assets/constants.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      label: '首页',
      firstMenu: Home
    }
  },
  {
    path: '/logisticsPlatform',
    name: LogisticsPlatform,
    component: () => import('../pages/productIntroduction/logisticsPlatform.vue'),
    meta: {
      label: '物流平台',
      firstMenu: ProductIntroduction
    }
  },
  {
    path: '/storagePlatform',
    name: StoragePlatform,
    component: () => import('../pages/productIntroduction/storagePlatform.vue'),
    meta: {
      label: '进销存平台',
      firstMenu: ProductIntroduction
    }
  },
  {
    path: '/tradePlatform',
    name: TradePlatform,
    component: () => import('../pages/productIntroduction/tradePlatform.vue'),
    meta: {
      label: '贸易平台',
      firstMenu: ProductIntroduction
    }
  },
  {
    path: '/logisticsScenarios',
    name: LogisticsScenarios,
    component: () => import('../pages/applicationScenarios/logistics.vue'),
    meta: {
      label: '物流总包',
      firstMenu: ApplicationScene
    }
  },
  {
    path: '/storageScenarios',
    name: StorageScenarios,
    component: () => import('../pages/applicationScenarios/storage.vue'),
    meta: {
      label: '智慧仓储',
      firstMenu: ApplicationScene
    }
  },
  {
    path: '/supplyChainScenarios',
    name: SupplyChainScenarios,
    component: () => import('../pages/applicationScenarios/supplyChain.vue'),
    meta: {
      label: '智慧仓储',
      firstMenu: ApplicationScene
    }
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import('../pages/aboutUs/index.vue'),
    meta: {
      label: '关于我们',
      firstMenu: AboutUs
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
