<template>
  <div class="homePage4">
    <div class="title">
      <div class="text1">平台大数据</div>
      <div class="text2">安全、稳定、高效</div>
    </div>
    <div class="content">
      <div class="item">
        <div class="value">
          <div class="num">
            <CountTo v-if="index === 4" :startVal="0" :endVal="2.20" :duration="2000" :decimals="2" />
            <span v-else>2.20</span>
          </div>
          <div class="unit">亿吨+</div>
        </div>
        <div class="key">业务总量</div>
      </div>
      <div class="item">
        <div class="value">
          <div class="num">
            <CountTo v-if="index === 4" :startVal="0" :endVal="2800" :duration="2000" :decimals="0" :separator="''" />
            <span v-else>2800</span>
          </div>
          <div class="unit">+</div>
        </div>
        <div class="key">服务货主</div>
      </div>
      <div class="item">
        <div class="value">
          <div class="num">
            <CountTo v-if="index === 4" :startVal="0" :endVal="30.20" :duration="2000" :decimals="2" />
            <span v-else>30.20</span>
          </div>
          <div class="unit">万+</div>
        </div>
        <div class="key">服务司机</div>
      </div>
      <div class="item">
        <div class="value">
          <div class="num">
            <CountTo v-if="index === 4" :startVal="0" :endVal="78.80" :duration="2000" :decimals="2" />
            <span v-else>78.80</span>
          </div>
          <div class="unit">万+</div>
        </div>
        <div class="key">整合运力</div>
      </div>
    </div>
  </div>
</template>
<script>
import { watch, ref } from 'vue'
import { CountTo } from 'vue3-count-to'
export default {
  name: 'HomePage4',
  components: {CountTo},
  props: ['currentPageIndex'],
  setup(props) {
    let img = require('@/assets/image/home/page2img@2x.png');
    let index = ref(props.currentPageIndex);
    watch(() => props.currentPageIndex, (value) => {
      index.value = value;
    })
    return {
      img,
      index
    }
  }
}
</script>
<style lang="sass" scoped>
@import '@/style/colorAndSize.sass'
.homePage4
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  .title
    height: 36%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .text1
      // margin-top: 0.86rem
      font-size: 0.54rem
      color: $fontColor
      font-weight: bold
      line-height: 0.75rem
      letter-spacing: 0.03rem
    .text2
      margin-top: 0.1rem
      font-size: 0.2rem
      color: $fontColor
      line-height: 0.28rem
      letter-spacing: 1px
  .content
    height: 64%
    padding-top: 0.39rem
    width: 100%
    display: flex
    justify-content: space-around
    .item
      display: flex
      flex-direction: column
      align-items: center
      .value
        display: flex
        justify-content: center
        align-items: flex-end
        .num
          line-height: 0.73rem
          font-family: DIN
          font-size: 0.60rem
          font-weight: bold
          color: $fontColor
        .unit
          margin-left: 0.08px
          margin-block: 0.05rem
          font-size: 0.28rem
          color: $fontColor
          font-weight: bold
          line-height: 0.40rem
      .key
        margin-top: 0.20rem
        font-size: 0.28rem
        color: $fontColor
        font-weight: bold
        line-height: 0.40rem
</style>