<template>
  <div class="homePage3">
    <div class="title">
      <div class="text1">应用场景</div>
      <div class="text2">场景、敏捷、共赢</div>
    </div>
    <div class="content">
      <div class="item" style="background: linear-gradient( 153deg, #00AF6E 0%, #00E08D 100%)">
        <div class="inner">
          <div class="titleBox">
            <div class="title1">物流总包服务</div>
            <div class="title2">多式联运一单到底</div>
          </div>
          <div class="contentBox">
            <div class="text3">
              <img class="icon" :src="img" alt="">
              <div class="textValue">公铁、公水、水铁“一单制”的应用帮助客户实现门到门、端到端的物流总包运输服务</div>
            </div>
            <div class="text3">
              <img class="icon" :src="img" alt="">
              <div class="textValue">提供客户管理、订单管理、结算管理等业务模式，既能统一管理又能分工协作</div>
            </div>
            <div class="btn" @click="handleGoPage('/logisticsScenarios')">查看详情</div>
          </div>
        </div>
      </div>
      <div class="item" style="background: linear-gradient( 153deg, #1D71FF 0%, #5392FF 100%)">
        <div class="inner">
          <div class="titleBox">
            <div class="title1">智慧物流园区服务</div>
            <div class="title2">数智化管控的站台港口</div>
          </div>
          <div class="contentBox">
            <div class="text3">
              <img class="icon" :src="img" alt="">
              <div class="textValue">实现园区自动化作业、智能化管理、信息化的发展要求。有效防堵漏洞、提高效率、降低成本</div>
            </div>
            <div class="text3">
              <img class="icon" :src="img" alt="">
              <div class="textValue">打通OMS/WMS/TMS三大系统，结合物联网智能硬件实现园区的智能化管理</div>
            </div>
            <div class="btn" @click="handleGoPage('/storageScenarios')">查看详情</div>
          </div>
        </div>
      </div>
      <div class="item" style="background: linear-gradient( 135deg, #F5A13C 0%, #FFBB58 100%)">
        <div class="inner">
          <div class="titleBox">
            <div class="title1">供应链物流服务</div>
            <div class="title2">需求发布到交付结算</div>
          </div>
          <div class="contentBox">
            <div class="text3">
              <img class="icon" :src="img" alt="">
              <div class="textValue">需求发布、在线竞价交易、在线合同、履约执行到交付结算的全流程管理</div>
            </div>
            <div class="text3">
              <img class="icon" :src="img" alt="">
              <div class="textValue">真正实现从需求到货物交付到客户手中全产业链条的线上化管理</div>
            </div>
            <div class="btn" style="margin-top: 0.66rem" @click="handleGoPage('/supplyChainScenarios')">查看详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  name: 'HomePage3',
  setup() {
    let img = require('@/assets/image/home/page3icon1@2x.png');

    const router = useRouter();
    const handleGoPage = (path) => {
      router.push({ path });
    }

    return {
      img,
      handleGoPage
    }
  }
}
</script>
<style lang="sass" scoped>
.homePage3
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  .title
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .text1
      font-size: 0.54rem
      color: #FFFFFF
      font-weight: bold
      line-height: 0.75rem
      letter-spacing: 0.03rem
    .text2
      margin-top: 0.1rem
      font-size: 0.2rem
      color: #FFFFFF
      line-height: 0.28rem
      letter-spacing: 1px
  .content
    margin-top: 0.72rem
    height: 5.6rem
    display: flex
    justify-content: space-between
    align-items: flex-end
    overflow: hidden
    .item
      width: 3.83rem
      height: 4.94rem
      padding: 0.36rem 0.4rem
      box-shadow: 0.1rem 0.13rem 0.15rem 0.15rem rgba(0,0,0,0.5)
      border-radius: 0px 0px 0.12rem 0px
      transition: all 0.1s linear
      overflow: hidden
      .inner
        width: 100%
        height: 5.4rem
      .titleBox
        .title1
          font-size: 0.34rem
          font-weight: bold
          color: #FFFFFF
          line-height: 0.48rem
          letter-spacing: 2px
        .title2
          margin-top: 0.06rem
          font-size: 0.28rem
          color: #FFFFFF
          line-height: 0.40rem
          letter-spacing: 1px
      .contentBox
        margin-top: 1.3rem
        .text3
          margin-bottom: 0.2rem
          display: flex
          .icon
            margin-top: 0.04rem
            width: 0.18rem
            height: 0.18rem
          .textValue
            margin-left: 0.08rem
            font-size: 0.18rem
            color: #FFFFFF
            line-height: 0.30rem
            letter-spacing: 1px
        .btn
          margin-top: 0.36rem
          width: 1.08rem
          height: 0.36rem
          line-height: 0.36rem
          text-align: center
          font-size: 0.16rem
          color: #ffffff
          border: 1px solid #FFFFFF
          cursor: pointer
    .item:hover
      height: 5.6rem
</style>