// 一级菜单name
export const Home = 'Home';
export const ProductIntroduction = 'ProductIntroduction';
export const ApplicationScene = 'ApplicationScene';
export const AboutUs = 'AboutUs';

// 二级菜单name
export const LogisticsPlatform = 'LogisticsPlatform';
export const StoragePlatform = 'StoragePlatform';
export const TradePlatform = 'TradePlatform';

export const LogisticsScenarios = 'LogisticsScenarios';
export const StorageScenarios = 'StorageScenarios';
export const SupplyChainScenarios = 'SupplyChainScenarios';

