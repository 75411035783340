<template>
  <div class="homePage">
    <transition name="fade">
      <div v-show="isFirstPage" class="_header" style="position: absolute">
        <HeaderBox />
      </div>
    </transition>
    <full-page :options="options" ref="fullpageRef">
      <div class="section section1 active">
        <div class="_homePage">
          <div class="_homePageInner">
            <HomePage1 />
          </div>
        </div>
      </div>
      <div class="section section2">
        <div class="_homePage">
          <div class="_homePageInner">
            <HomePage2 />
          </div>
        </div>
      </div>
      <div class="section section3">
        <div class="_homePage">
          <div class="_homePageInner">
            <HomePage3 />
          </div>
        </div>
      </div>
      <div class="section section4">
        <div class="_homePage">
          <div class="_homePageInner">
            <HomePage4 :currentPageIndex="currentPageIndex" />
          </div>
        </div>
      </div>
      <div class="section section5">
        <div class="_homePage">
          <div class="_homePageInner">
            <HomePage5 />
          </div>
        </div>
      </div>
      <div class="section section6">
        <div class="_homePage">
          <div class="_homePageInner">
            <FooterBox />
          </div>
        </div>
      </div>
    </full-page>
    <img v-show="showTopBtn" @click="handleToTop" class="toTopBtn" :src="topImg" alt="">
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import HeaderBox from '@/components/HeaderBox.vue'
import HomePage1 from './page1.vue'
import HomePage2 from './page2.vue'
import HomePage3 from './page3.vue'
import HomePage4 from './page4.vue'
import HomePage5 from './page5.vue'
import FooterBox from '@/components/footerBox.vue'
export default {
  name: 'HomePage',
  components: {
    HeaderBox,
    HomePage1,
    HomePage2,
    HomePage3,
    HomePage4,
    HomePage5,
    FooterBox
  },
  setup() {
    const topImg = require('@/assets/image/home/top@2x.png');
    let isFirstPage = ref(true);
    let showTopBtn = ref(false);
    let currentPageIndex = ref(0);
    const afterLoadFn = (anchorLink, index) => {
      // isFirstPage.value = index.isFirst;
      showTopBtn.value = !index.isFirst;
      if (index.index === 0) {
        isFirstPage.value = index.isFirst;
      }
    }
    const onLeaveFn = (index, nextIndex) => {
      currentPageIndex.value = nextIndex.index + 1;
      if (nextIndex.index !== 0) {
        isFirstPage.value = nextIndex.isFirst;
      }
    }
    let fullpageRef = ref();
    let options = reactive({
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        scrollingSpeed: 500, // 动画时间
        easing: 'linear', // 动画
        afterLoad: afterLoadFn, // 滚动到某一屏的回调
        onLeave: onLeaveFn //滚动前的回调函数
    })
    const handleToTop = () => { // 向 第几页 滚动
        fullpageRef?.value?.api.moveTo(1,0);
        // fullpageRef?.value?.api.moveTo(3,2); // 滚动向 竖第三 横第二 页 
        // fullpage_api.moveTo('firstSlide', 2);
        // fullpage_api.moveTo(3, 0);
        // fullpage_api.moveTo(3);
    }

    return {
      topImg,
      fullpageRef,
      options,
      isFirstPage,
      currentPageIndex,
      showTopBtn,
      handleToTop
    }
  }
}
</script>
<style lang="sass">
@import '@/style/colorAndSize.sass'
// fade
.fade-enter-active,
.fade-leave-active
  transition: opacity 0.2s
.fade-enter,
.fade-leave-to
  opacity: 0
.homePage
  width: 100%
  height: 100%
  overflow: hidden
  position: relative
  .fp-watermark
    display: none !important
  .fp-overflow
    height: 100%
  .toTopBtn
    width: 0.56rem
    height: 0.56rem
    position: fixed
    right: 0.3rem
    bottom: 1.6rem
    z-index: 99
    cursor: pointer
  .section
    background-size: cover
    background-position: center
  .section1
    background-image: url('@/assets/image/home/bg1.jpg')
  .section2
    background-image: url('@/assets/image/home/bg2.jpg')
  .section3
    background-image: url('@/assets/image/home/bg3.jpg')
  .section4
    background-image: url('@/assets/image/home/bg4.jpg')
  .section5
    background: #F6F6F6
  .section6
    background: #303335
</style>
