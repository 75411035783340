<template>
  <div class="homePage5">
    <div class="titleBox">
      <div class="text1">我们的优势</div>
      <div class="text2">专业、创新、定制</div>
    </div>
    <div class="content">
      <div class="item">
        <div class="key">
          <img class="icon" :src="img1" alt="">
          <div class="title">行业理解</div>
        </div>
        <div class="text">企业从2015年就开始研发物流智慧平台，行业摸爬滚打八九年，积累了丰富的经验</div>
      </div>
      <div class="item">
        <div class="key">
          <img class="icon" :src="img2" alt="">
          <div class="title">持续的创新</div>
        </div>
        <div class="text">紧跟行业发展，洞察市场需求。不断的创新，通过新技术的应用逐步完善系统</div>
      </div>
      <div class="item">
        <div class="key">
          <img class="icon" :src="img3" alt="">
          <div class="title">定制化的服务</div>
        </div>
        <div class="text">除了标准服务，我们可以根据业务需求提供定制化服务，各模块之间既能独立运作又能协同运作</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomePage5',
  setup() {
    let img1 = require('@/assets/image/home/page5img1@2x.png');
    let img2 = require('@/assets/image/home/page5img2@2x.png');
    let img3 = require('@/assets/image/home/page5img3@2x.png');

    return {
      img1,
      img2,
      img3
    }
  }
}
</script>
<style lang="sass" scoped>
@import '@/style/colorAndSize.sass'
.homePage5
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  // justify-content: center
  align-items: center
  .titleBox
    height: 36%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .text1
      font-size: 0.54rem
      color: $fontColor
      font-weight: bold
      line-height: 0.75rem
      letter-spacing: 0.03rem
    .text2
      margin-top: 0.10rem
      font-size: 0.20rem
      color: $fontColor
      line-height: 0.28rem
      letter-spacing: 1px
  .content
    width: 100%
    height: 64%
    display: flex
    justify-content: space-between
    .item
      width: 3.70rem
      height: 84%
      padding: 0.60rem 0.42rem 0.85rem
      background: #FFFFFF
      box-shadow: 2px 2px 0.14rem 0.05rem rgba(0,0,0,0.04)
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center
      .key
        display: flex
        flex-direction: column
        align-items: center
      .icon
        width: 1.20rem
        height: 1.20rem
      .title
        margin-top: 0.15rem
        font-size: 0.30rem
        color: $fontColor
        font-weight: bold
        line-height: 0.42rem
      .text
        // margin-top: 0.37rem
        font-size: 0.20rem
        color: #717171
        line-height: 0.28rem
</style>