import { createStore } from 'vuex'

export default createStore({
  state: {
    _isPhone: false,
    _headerHidden: false,
  },
  getters: {

  },
  mutations: {
    ChangeIsPhoneFlag(state, isPhone) {
      if (isPhone) {
        state._isPhone = true;
      } else {
        state._isPhone = false;
      }
    },
    ChangeHeaderHiddenFlag(state, value) {
      state._headerHidden = value;
    }
  },
  actions: {

  },
  modules: {

  }
})
