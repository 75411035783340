<template>
  <div :class="outerMouseOver ? 'header header2' : 'header'" @mouseover="handleMouseOverOuter" @mouseout="handleMouseOutOuter">
    <div class="inner">
      <img class="logo" @click="handleGoPage('/')" :src="outerMouseOver ? logoImg2 : logoImg1" alt="">
      <div class="menuBox">
        <div :class="firstMenuName === firstMenu.Home ? 'firstMenu active' : 'firstMenu'" @click="handleGoPage('/')">
          <span>首页</span>
        </div>
        <div :class="firstMenuName === firstMenu.ProductIntroduction ? 'firstMenu active' : 'firstMenu'">
          <span>产品介绍</span>
          <div class="secondMenuBox">
            <div :class="secondMenuName === secondMenu.LogisticsPlatform? 'secondMenu action' : 'secondMenu'" @click="handleGoPage('/logisticsPlatform')">物流平台</div>
            <div :class="secondMenuName === secondMenu.StoragePlatform? 'secondMenu action' : 'secondMenu'" @click="handleGoPage('/storagePlatform')">进销存平台</div>
            <!-- <div :class="secondMenuName === secondMenu.TradePlatform? 'secondMenu action' : 'secondMenu'" @click="handleGoPage('/tradePlatform')">贸易平台</div> -->
          </div>
        </div>
        <div :class="firstMenuName === firstMenu.ApplicationScene ? 'firstMenu active' : 'firstMenu'">
          <span>应用场景</span>
          <div class="secondMenuBox">
            <div :class="secondMenuName === secondMenu.LogisticsScenarios? 'secondMenu action' : 'secondMenu'" @click="handleGoPage('/logisticsScenarios')">物流总包</div>
            <div :class="secondMenuName === secondMenu.StorageScenarios? 'secondMenu action' : 'secondMenu'" @click="handleGoPage('/storageScenarios')">智慧仓储</div>
            <div :class="secondMenuName === secondMenu.SupplyChainScenarios? 'secondMenu action' : 'secondMenu'" @click="handleGoPage('/supplyChainScenarios')">供应链物流</div>
          </div>
        </div>
        <div :class="firstMenuName === firstMenu.AboutUs ? 'firstMenu active' : 'firstMenu'" @click="handleGoPage('/aboutUs')">
          <span>关于我们</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Home, ProductIntroduction, ApplicationScene, AboutUs, LogisticsPlatform, StoragePlatform, TradePlatform, LogisticsScenarios, StorageScenarios, SupplyChainScenarios } from '../assets/constants.js'
export default {
  name: 'HeaderBox',
  setup() {
    const logoImg1 = require('@/assets/image/header/logo_white@2x.png');
    const logoImg2 = require('@/assets/image/header/logo_green@2x.png');
    const route = useRoute();
    const router = useRouter();

    let firstMenuName = ref(route.meta.firstMenu);
    let outerMouseOver = ref(false);
    if (firstMenuName.value !== Home) {
      outerMouseOver.value = true;
    }
    const handleMouseOverOuter = () => {
      if (firstMenuName.value !== Home) return false;
      outerMouseOver.value = true;
    }
    const handleMouseOutOuter = () => {
      if (firstMenuName.value !== Home) return false;
      outerMouseOver.value = false;
    }
    watch(() => route.meta.firstMenu, (value) => {
      firstMenuName.value = value;
      if (value !== Home) {
        outerMouseOver.value = true;
      }
    })
    let secondMenuName = ref(route.name);
    watch(() => route.name, (value) => {
      secondMenuName.value = value;
    })
    let firstMenu = {
      Home,
      ProductIntroduction,
      ApplicationScene,
      AboutUs
    }
    let secondMenu = {
      LogisticsPlatform,
      StoragePlatform,
      TradePlatform,
      LogisticsScenarios,
      StorageScenarios,
      SupplyChainScenarios
    }

    const handleGoPage = (path) => {
      router.push({ path });
    }

    return {
      logoImg1,
      logoImg2,
      firstMenuName,
      firstMenu,
      secondMenuName,
      secondMenu,
      outerMouseOver,
      handleMouseOverOuter,
      handleMouseOutOuter,
      handleGoPage
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/style/colorAndSize.sass'
.header
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  border-bottom: 1px solid rgba(255,255,255,0.21)
  .inner
    width: $contentWidth
    height: 100%
    display: flex
    align-items: center
    .logo
      width: 1.35rem
      height: 0.39rem
      cursor: pointer
    .menuBox
      margin-left: 2.62rem
      height: 100%
      display: flex
      .firstMenu
        width: 1.60rem
        height: 100%
        font-size: 0.18rem
        font-weight: bold
        text-align: center
        color: #ffffff
        cursor: pointer
        position: relative
        span
          display: inline-block
          height: 100%
          line-height: $headerHeight
        .secondMenuBox
          display: none
          width: 100%
          padding: 0.09rem 0
          background: #FFFFFF
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.18)
          position: absolute
          top: $headerHeight
          left: 0
          .secondMenu
            width: 100%
            height: 0.52rem
            line-height: 0.52rem
            font-size: 0.16rem
            color: $fontColor
            cursor: pointer
          .secondMenu:hover
            background: #F8F8F8
          .action
            color: $fontActionColor
      .active
        span
          border-bottom: 1px solid #ffffff
      .firstMenu:hover
        background: #F8F8F8
        .secondMenuBox
          display: block
.header2
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.13)
  background: #ffffff
  .inner
    .menuBox
      .firstMenu
        color: $fontColor
      .active
        color: $fontActionColor
</style>
