<template>
  <div class="app">
    <div class="mainBox" ref="mainBoxDom">
      <router-view/>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'
export default {
  name: 'App',
  setup() {
    const store = useStore();
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (flag) {
      // console.log('当前设备为移动端');
      console.log('officialWebsite_' + process.env.NODE_ENV + '_phone');
      store.commit('ChangeIsPhoneFlag', true);
    } else {
      // console.log('当前设备为PC端');
      console.log('officialWebsite_' + process.env.NODE_ENV + '_pc');
      store.commit('ChangeIsPhoneFlag', false);
    }
    
    const route = useRoute();
    let mainBoxDom = ref(null);
    watch(() => route.name, () => {
      mainBoxDom.value.scrollTop = 0;
    })

    return {
      mainBoxDom
    }
  }
}
</script>
<style lang="sass" scoped>
// @import './style/colorAndSize.sass'
.app
  width: 100%
  height: 100%
  background: #FFFFFF
  overflow: hidden
  position: relative
  .mainBox
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    overflow: auto
</style>
