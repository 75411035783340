// import store from '../store'
export default function () {
  let docEl = document.documentElement
  let originWidth = 1920 // ui设计稿的宽度，一般750或640
  let fontValue
  let currentClientWidth
  let resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
  let recalculate = function () {
    currentClientWidth = docEl.clientWidth
    if (currentClientWidth <= 1220) {
      currentClientWidth = 1220
    }
    // if (currentClientWidth >= 2200) {
    //   currentClientWidth = 2200
    // }
    fontValue = currentClientWidth / originWidth * 100
    docEl.style.fontSize = fontValue + 'px'
    let screenSize = docEl.clientWidth + '*' + docEl.clientHeight
    // store.dispatch('SetScreenSize', screenSize)
    console.log(screenSize)
  }
  if (!document.addEventListener) return
  window.addEventListener(resizeEvt, recalculate, false)
  recalculate()
  //document.addEventListener('DOMContentLoaded', recalculate, false)
}
