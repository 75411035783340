<template>
  <div class="footerBox">
    <div class="content1">
      <img class="logoImg" :src="logoImg" alt="">
      <div class="menuBox">
        <div class="key">产品生态</div>
        <div class="value" @click="handleGoPage('/logisticsPlatform')">多好运物流</div>
        <div class="value" @click="handleGoPage('/storagePlatform')">多好运进销存</div>
        <!-- <div class="value" @click="handleGoPage('/tradePlatform')">多好运贸易</div> -->
      </div>
      <div class="menuBox">
        <div class="key">应用场景</div>
        <div class="value" @click="handleGoPage('/logisticsScenarios')">物流总包</div>
        <div class="value" @click="handleGoPage('/storageScenarios')">智慧仓储</div>
        <div class="value" @click="handleGoPage('/supplyChainScenarios')">供应链物流</div>
      </div>
      <div class="contactType">
        <div class="key">联系电话</div>
        <div class="value">020-39997760</div>
        <div class="key">企业邮箱</div>
        <div class="value">gdll-sw@duohaoyun.cn</div>
      </div>
    </div>
    <div class="content2">
      <div class="item">
        <img class="pic" :src="companyImg" alt="">
        <span>货主端APP</span>
      </div>
      <div class="item">
        <img class="pic" :src="driverImg" alt="">
        <span>司机端APP</span>
      </div>
      <div class="item">
        <img class="pic" :src="storageImg" alt="">
        <span>进销存端APP</span>
      </div>
    </div>
    <div class="bottomBox">
      <a target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn/#/Integrated/index"><span>闽ICP备20003582号-1</span></a>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  name: 'FooterBox',
  setup() {
    const logoImg = require('@/assets/image/header/logo_white@2x.png');
    const companyImg = require('@/assets/image/home/company@2x.png');
    const driverImg = require('@/assets/image/home/driver@2x.png');
    const storageImg = require('@/assets/image/home/storage@2x.png');

    const router = useRouter();
    const handleGoPage = (path) => {
      router.push({ path });
    }

    return {
      logoImg,
      companyImg,
      driverImg,
      storageImg,
      handleGoPage
    }
  }
}
</script>
<style lang="sass" scoped>
.footerBox
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  .content1
    width: 100%
    margin-top: 1rem
    display: flex
    justify-content: space-between
    .logoImg
      width: 1.35rem
      height: 0.39rem
    .menuBox
      .key
        font-size: 0.16rem
        color: #B3B3B3
        line-height: 0.22rem
        letter-spacing: 1px
        margin-bottom: 0.24rem
      .value
        font-size: 0.16rem
        color: #FFFFFF
        line-height: 0.22rem
        letter-spacing: 1px
        margin-bottom: 0.14rem
        cursor: pointer
    .contactType
      .key
        font-size: 0.16rem
        color: #B3B3B3
        line-height: 0.22rem
        letter-spacing: 1px
        margin-bottom: 0.18rem
        text-align: right
      .value
        font-size: 0.32rem
        color: #FFFFFF
        font-weight: bold
        line-height: 0.36rem
        letter-spacing: 1px
        margin-bottom: 0.27rem
        text-align: right
  .content2
    width: 100%
    display: flex
    .item
      margin-right: 0.68rem
      display: flex
      flex-direction: column
      align-items: center
      .pic
        width: 1.52rem
        height: 1.52rem
      span
        margin-top: 0.17rem
        font-size: 0.16rem
        color: #B3B3B3
        line-height: 22px
        letter-spacing: 1px
  .bottomBox
    width: 100%
    height: 1.14rem
    border-top: 1px solid #444748
    text-align: center
    display: flex
    justify-content: center
    a
      display: inline-block
      height: 0.14rem
      line-height: 0.14rem
      margin-top: 0.28rem
    span
      font-size: 0.12rem
      color: rgba(255,255,255,0.6)
</style>